@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Limelight&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap');

/* for notched phones */
@supports (padding: max(0px)) {
  body,
  header,
  footer {
    padding-left: min(0vmin, env(safe-area-inset-left));
    padding-right: min(0vmin, env(safe-area-inset-right));
  }
}

:root {
  /* N.B. CSS variables are passed to children! */
  --dice-cup-height: 30vh;
  /* N.B. keeps dice cup container in proportion to the dice cup svgs */
  --dice-cup-width: calc(var(--dice-cup-height) * (130 / 179));
  --dice-cup-right-margin: 10px;

  --max-banked-dice-size: 40px;
  --min-banked-dice-size: 10px; /* effectively no minimum banked dice size */

  /* --max-rolled-dice-size: 60px; */
  --min-rolled-dice-size: 28px;

  --header-height-estimate: 50px;

  --turn-score-height: 52px;
}

@media (max-height: 500px) {
  :root {
    --header-height-estimate: 33px;
  }
}

body {
  margin: 0;
  font-family: 'Playfair Display', serif;
  color: rgb(180, 176, 85);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  user-select: none;
  cursor: default;

  overscroll-behavior: none;
  /* prevent overscrolling on safari too (doesn't support `overscroll-behavior`): */
  position: fixed;
  overflow: hidden;
  width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
